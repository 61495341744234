/* Login Page Styles */


.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    /* background: #f7f7f7; */
  }
  
  .login-box {
    background: white;
    width: 400px;
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .login-box h2 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .login-box p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .login-buttons {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .google-login-btn,
  .facebook-login-btn {
    display: flex !important;
    justify-content: center !important;
    width: 100%;
    font-size: 16px;
  }
  
  .google-login-btn {
    background: #4285F4;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
  }
  
  .facebook-login-btn {
    background: #1877F2 !important;
    color: white !important;
    padding: 10px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
  }
  
  .divider {
    margin: 20px 0;
    position: relative;
    text-align: center;
  }
  
  .divider span {
    background: white;
    padding: 0 10px;
    color: #aaa;
    font-size: 14px;
    position: relative;
  }
  
  .divider::before,
  .divider::after {
    content: '';
    height: 1px;
    width: 40%;
    background: #ddd;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .divider::before {
    left: 0;
  }
  
  .divider::after {
    right: 0;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .login-form input {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .login-btn {
    padding: 10px;
    background: #333;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .signup-link {
    margin-top: 15px;
    color: #555;
  }
  
  .signup-link a {
    color: #4285F4;
    text-decoration: none;
    font-weight: bold;
  }
  