*{margin:0px;padding: 0px;}

.product-container{display: flex;    width: 90%;
  margin: auto;}
.prod-details{margin-left:1em;}
/* .product-container .product-img{border: 1px;} */

/* Form Group */
.form-wrap{display: flex; flex-direction: row;}
.form-wrap .form-group{flex-grow: 1; margin-right: 1rem;}
.form-group {
    /* display: flex; 
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;*/
    margin-bottom: 15px;
  }

  .form-group > div {display: flex;flex-wrap: wrap;margin-top:1rem ;}
  .form-group > div label{font-weight: normal; cursor: pointer;background:rgba(241, 235, 220, 1); border-radius: 4px; text-align: center; padding: 4px;}
  .form-group > div label input{width: 100%;}
  .form-group label {
    font-size: 1rem;
    font-weight: bold;
    color: #555;
    margin-right: 1rem;
    margin-bottom: 1rem;
    display: block;
  }
  
  .form-group select,
  .form-group input,
  .form-group textarea {
    width: calc(100% - 1rem);
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  .order-summary h2{
    margin-bottom: 24px;
  }
  .order-summary .order-item{ margin-bottom: 8px;}

  .form-group input[type=radio] {
    width:auto;margin-right: 8px;}

.payment-section h1{margin: 24px 0;}
.payment-section {flex: 1;}

.payment-section form{padding: 24px;}
.payment-section .payment-options{margin-bottom: 16px;}
.payment-section .payment-options label{
  padding: 8px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid rgb(228 231 235);
  display: inline-block;
  margin-right: 24px;}

  .payment-section .payment-options label input{margin-right: 8px;}
 

  .card-input iframe{height: 24px;}
  .card-input iframe .CardField-number{background: #f00 !important;}
/* 
  
  .form-group label {
    font-weight: bold;
    display: block;
    margin-bottom: 1rem;
  }
  
  .form-group input,
  .form-group textarea,
  .form-group select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  } */
  
  textarea {
    resize: vertical;
  }
  .back-btn{padding: 0.75rem;
    text-decoration: none;
    font-size: 1rem;
    color: white;
    background-color: #d32f2f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .back-btn-wrap{    max-width: 1044px;
    margin: 1rem auto;
    padding: 0rem;}

    .beverages .item-card{border: 1px solid #e7dfcc;}
    .beverages .category-card-image{height: 100px;}

    .beverage-quantity{margin-top: 20px;}
    .beverage-quantity input{padding: 8px; width: calc(100% - 18px); border-radius: 6px; border: 1px solid #555;}