.my-orders-container {
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.my-orders-container h1 {
  text-align: center;
  margin-bottom: 2rem;
  font-size: 2rem;
  color: #333;
}

.orders-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.order-card {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.order-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f8f8f8;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.order-header h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
}

.order-status {
  padding: 0.3rem 0.7rem;
  border-radius: 12px;
  font-size: 0.9rem;
  color: #f1ede9;
}

.order-status.pending {
  background-color: #f0ad4e;
}

.order-status.accepted {
  background-color: #5cb85c;
}

.order-status.declined {
  background-color: #d9534f;
}

.order-body {
  padding: 1rem;
}

.order-info {
  text-align: right;
  margin-bottom: 1rem;
}

.order-total {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}

.order-items {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.order-item-details {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #f0f0f0;
}

.order-item-details:last-child {
  border-bottom: none;
}

.order-item-left {
  flex: 0 0 auto;
}

.order-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 6px;
}

.order-item-middle {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.item-name {
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.item-quantity {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.order-item-right {
  flex: 0 0 auto;
  text-align: right;
}

.item-price {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  margin: 0;
}
