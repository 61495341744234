.register-form {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    /* border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #f9f9f9; */
  }
  
  .register-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .register-form input,
  .register-form textarea {
    display: block;
    width: calc(100% - 20px);
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .contact-section {
    margin: 20px 0;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  button {
    width: 100%;
    margin: 10px 0;
    padding: 10px 20px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    /* background-color: #0056b3; */
    background:rgb(243, 203, 1) ;
    color: #000;
  }
  