.cart-container {
    max-width: 800px;
    margin-top: 18px;
    padding: 20px;
    width: 100%;
  }

  .cart-container i{    width: 100%;
    text-align: center;
    margin: 24px 0;
    font-size: 36px;
    color: rgba(0, 0, 0, 0.5);}

  .cart-container > p, .cart-container > h1{text-align: center;    margin-bottom: 20px;}
  
  .cart-item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
  }
  
  .cart-item-image {
    width: 160px;
    height: 160px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .cart-item-details {
    line-height: 1.6;
  } 
  
  .cart-item-quantity {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .cart-item-quantity input{width:60px;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;}
  .delete-btn {
    background: #ff9797;
    border: 1px solid #e37474;
    color: #3e1616;
      font-size: 16px;
      padding: 8px 16px;
  }
  
  .delete-btn:hover {
    background-color: #b71c1c;
  }
  .cart-items{border: 1px solid;}
  .cart-wrap{    
        display: flex;
        align-items: flex-start;
        width: 820px;
        margin: auto;
        gap: 40px;}
  .cart-summary h2{ margin-bottom: 20px;}
  .cart-summary {
    margin: 20px 0 20px 0;
    border-top: 1px solid #ddd;
    padding-top: 20px;
    box-shadow: none !important;
    background: rgba(248, 249, 250, 1);
  }
  
  .tip-selection {
    margin: 10px 0;
  }
  .tip-selection select{padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;}
  
  .checkout-btn {
    width: 100%;
    padding: 10px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .checkout-btn:hover {
    background-color: #388e3c;
  }
  .order-summary {
    background: #fff;
    padding: 1.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    max-width: 600px;
    margin: 2rem auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .order-summary h2 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    text-align: center;
    font-size: 1.5rem;
    color: #333;
  }
  
  .order-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 0;
    border-bottom: 1px solid #eee;
  }
  
  .order-item:last-child {
    border-bottom: none;
  }
  
  .order-item-left {
    flex: 0 0 auto;
  }
  
  .order-item-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 4px;
  }
  
  .order-item-middle {
    flex: 1;
    padding: 0 1rem;
  }
  
  .order-item-name {
    font-weight: 600;
    margin: 0;
    font-size: 1rem;
    color: #333;
  }
  
  .order-item-quantity {
    margin: 0.25rem 0 0 0;
    font-size: 0.9rem;
    color: #666;
  }
  
  .order-item-right {
    flex: 0 0 auto;
    text-align: right;
  }
  
  .order-item-price {
    font-weight: 600;
    font-size: 1rem;
    color: #333;
  }
  
  /* Order Totals Section */
  .order-summary-totals {
    margin-top: 1rem;
    border-top: 1px solid #ddd;
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .order-summary-totals p,
  .order-summary-totals h3 {
    margin: 0;
    font-size: 1rem;
    color: #333;
  }
  
  .order-total {
    font-size: 1.2rem;
    font-weight: 600;
  }
 .store-closed-message{
    background: #f44336;
    color: white;
    padding: 1rem;
    text-align: center;
  }
 
  
 
 