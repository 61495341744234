.payment-container {
  display: flex;
  justify-content: space-between;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}



.order-summary {
  flex: 1;
  margin-right: 20px;
}

.order-item {
  margin-bottom: 10px;
}



.card-input {
  margin-bottom: 20px;
}

.payment-options {
  margin-bottom: 20px;
}

.pay-button {
  background-color: #6772e5;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #ccc;
}

.error-message {
  color: red;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 80%;
}

.popup h2 {
  margin-bottom: 10px;
}