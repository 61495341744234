.layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
}


.topheader{background:#f9f5de; border-bottom: 1px solid #888; padding: 24px 0; text-align: center;font-weight: 600; color:#545353;}
.store_time{    position: absolute;
  right: 0px;
  top: 133px;
  border-radius: 8px 0px 0px 16px;
  background: #f5e2a8;
  box-shadow: -14px 7px 16px rgba(0, 0, 0, 0.3);
  padding: 24px;
  width: 260px;
  border: 4px solid #ed3a3d;
  border-right: 0px;}

  .store_time h4{margin-bottom: 16px;}

.store_time img{    position: absolute;
  left: -65px;
  top: -24px;
  width: 100px;}
.pickdel-info{
  display:flex; list-style: none; padding: 10px; margin: 0;
}

.navbar {
  /* background-color:#a9091a; */
  background: rgb(255,251,240);
  background: linear-gradient(90deg, rgba(255,251,240,1) 0%, rgba(227,176,24,0.7) 30%, rgba(169,9,26,1) 60%, rgba(169,9,26,1) 100%);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding-left: 16px;
}
.logo img{height: 58px;}
.nav-links ul {
  list-style: none;
  display: flex;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.nav-links ul li a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 1rem;
  display: block;
  border-bottom:3px solid  rgba(255, 200, 0,0);
}

.nav-links ul li a:hover{color: rgb(255, 255, 0); border-bottom:3px solid  rgb(255, 255, 0); }

.user-menu {
  position: relative;
  display: inline-block;
}

.user-menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.user-menu-button:after {
  content: '▼';
  margin-left: 0.5rem;
  font-size: 0.8rem;
}

.user-menu-dropdown {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  background: white;
  color: black;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 150px;
}

.user-menu-dropdown.active {
  display: block;
}

.user-menu-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
}

.user-menu-dropdown ul li {
  display: block;
  padding: 0.8rem 1rem;
  border-bottom: 1px solid #f0f0f0;
  font-size: 1rem;
}

.user-menu-dropdown ul li:last-child {
  border-bottom: none;
}

.user-menu-dropdown ul li:hover {
  background: rgb(252, 231, 115);
}

.main-layout {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.main-banner {
  text-align: center;
  background: url(../images/header.png);
  color: black;
  padding: 2rem;
  height: 227px;
  background-position: center;
  background-size: cover;
}
.main-banner button{
display: inline-flex;
justify-content: center;
align-items: center;
gap: 10px;
top:100px;
margin-top: 160px;
}

.main-banner button, .ordernow{
  width: 140px;
  padding: 9px 20px;
  border-radius: 20px;
background: #FFC700;
color:#252525;
font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
font-size: 18px;
font-weight: 600;
}

.main-banner h1 {
  font-size: 2.1rem;
  margin-bottom: 1rem;
  display: inline-block;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 8px 16px;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.main-banner p {
  font-size: 1.5rem;
}

.content-section {
  display: flex;
  padding: 2rem;
}

.main-content {
  flex: 3;
  /* padding: 1rem;
  background:rgba(241, 235, 220, 1);
  border-radius: 16px; */
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
  
}
.min-h-screen{background:rgba(241, 235, 220, 1);
  border-radius: 16px;}

.sidebar {
  flex: 1;
  margin-left: 2rem;
  padding: 1rem;
  background:rgba(241, 235, 220, 1);
  border-radius: 16px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.sidebar h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar ul li {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  border: 2px solid rgba(221, 212, 191,1);
}

.footer {
  text-align: center;
  background-color: #333;
  color: white;
  padding: 1rem;
}

.footer p {
  margin: 0;
}
.user-menu {
  position: relative;
}

.user-menu-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
}

.user-menu-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  color: black;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  overflow: hidden;
  display: none; /* Initially hidden */
}

.user-menu-dropdown.active {
  display: block;
  width: 180px;
}

.user-menu-dropdown ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-menu-dropdown li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
}

.user-menu-dropdown li:hover {
  background: #f5f5f5;
}

/* Order Type Display and Edit Button */
.order-type-display {
  display: flex;
  align-items: center;
  font-size: 1rem;
  /* font-weight: 600;
  color: #444; */
  padding: 0 0 0 24px;
  margin-right: 0rem;
  background:rgb(189 33 49);
  border-radius: 8px;
}

.edit-order-type-btn {
  font-size: 1.2rem;           /* Make the icon slightly larger */
  color: #fff;              /* A pleasant blue color */
  border: none;
  background: none;
  cursor: pointer;
  transition: transform 0.2s ease, color 0.2s ease;
  margin-left: 0.5rem;
  margin-top: 0px;
  margin-bottom: 0px;
 
}
.hdr-rightsection{
  display: flex;
  align-items: center;
}

.edit-order-type-btn:hover {
  transform: scale(1.2);       /* Subtle zoom on hover */
  color:#fff;              /* Darker blue on hover */
}


/* Make sure any paragraphs inside order-type-display display inline */
.order-type-display p {
  display: inline;
  margin: 0;
  padding: 0;
  line-height: 1;
}

/* Optional: style the icons (adjust size/margin as needed) */
.order-type-display i {
  margin-right: 0.3rem;
  vertical-align: middle;
}
/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6); /* A slightly darker overlay */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;                /* Ensure it overlays all content */
}

/* Modal Content */
.modal-content {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  text-align: center;
}

/* Address Form (within the modal) */
.address-form {
  margin-top: 1rem;
  text-align: left;
}

.address-form input {
  width: 100%;
  padding: 0.5rem;
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.store-closed-message{

  background-color: #f8d7da;
  color: #721c24;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: center;
}

