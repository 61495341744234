.contact-management {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .add-contact {
margin-top: 24px;
  }

  .contact-card{background:rgba(248,249,250,1);
    border: 1px solid rgb(228 231 235);
    border-radius: 8px;
    padding: 16px;
    line-height: 2;
    color: rgba(0, 0, 0, 0.8);margin-bottom: 24px;}

    .contact-cta{display: flex;column-gap: 24px;}
    .contact-cta button{    background: #e3effd;
      color:#454646;
      font-size: 16px;
      border: 1px solid #648db9;}
      .contact-cta button.delete { 
        background: #ff9797;
    border: 1px solid #e37474;
    color: #3e1616;
      }
.add-contact-btn{
  background: #e3effd;
  color: #454646;
  font-size: 16px;
  border: 1px solid #648db9;
  margin-top: 24px;
  padding: 16px 24px;
}
.add-contact-btn i{
  font-size: 24px;
    font-weight: 100;
    margin-bottom: 16px;
}



  .add-contact h2{font-weight: 200; margin-bottom:10px;}
  .form-wrap{display: flex;}
  

  .new-contact-form, .contact-item {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 8px;
  }
  
  .new-contact-form input, .new-contact-form textarea,
  .contact-item input, .contact-item textarea {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
  }
  
  button {
    margin-right: 10px;
    padding: 8px 12px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background: #0056b3;
  }
  
  /* Container for the action buttons */
.action-buttons {
  display: flex;
  justify-content: space-between; /* Align buttons to either end */
  margin-top: 20px; /* Add space from the form above */
  padding: 10px;
  width: 100%; /* Ensure buttons stretch across the container */
}

/* Style for the back button (Home) */
.back-btn-wrap {
  display: flex;
  align-items: center;
}

.back-btn {
  display: flex;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.back-btn:hover {
  background-color: #0056b3;
}

.back-btn i {
  margin-right: 8px;
}

/* Style for the checkout button */
.checkout-btn-wrap {
  display: flex;
  align-items: center;
}

.checkout-btn {
  /* display: flex; */
  align-items: center;
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.checkout-btn:hover {
  background-color: #218838;
}

.checkout-btn i {
  margin-right: 8px;
}
