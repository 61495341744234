/* General Container Styling */
.details-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 1.5rem;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    border: 1px solid #e7dfcc;
    font-family: Arial, sans-serif;
  }
  
  /* Image Section */
  .details-image {
    max-width: 140px;
    height: auto;
    margin-bottom: 1rem;
    border-radius: 8px;
    border: 1px solid #ddd;
  }
  
  /* Heading and Price */
  .details-title {
    font-size: 2rem;
    margin-bottom: 0.5rem;
    color: #333;
   
  }
  
  .details-price {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #d32f2f;
    /* text-align: center; */
  }
  
  /* Form Styling */
  .details-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
    margin: auto;
  }
  
  
  
  /* Total Price */
  .details-total {
    font-size: 1.25rem;
    margin-top: 1rem;
    font-weight: bold;
    text-align: right;
    color: #333;
  }
  
  /* Add to Cart Button */
  .add-to-cart-btn {
    padding: 0.75rem;
    font-size: 1rem;
    color: white;
    background-color: #d32f2f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    width: 100%;
  }
  
  .add-to-cart-btn:hover {
    background-color: #b71c1c;
  }
  /* General container styling */
  .product-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 24px;
    border-radius: 12px;
    background: #f9fafb;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  @media (min-width: 768px) {
    .product-container {
      flex-direction: row;
      text-align: left;
    }
  }

/* Image Styling */
.prod-img img {
  width: 100%;
  max-width: 320px;
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease-in-out;
}

.prod-img img:hover {
  transform: scale(1.05);
}

/* Product Details */
.prod-details {
  flex: 1;
  padding-left: 24px;
}

.prod-details h1 {
  font-size: 28px;
  font-weight: bold;
  color: #1e293b;
}

.details-price {
  font-size: 20px;
  font-weight: 600;
  color: #22c55e;
  margin-top: 10px;
}

/* Description Box */
.description-box {
  margin-top: 16px;
  background: linear-gradient(to right, #eef2ff, #e0e7ff);
  padding: 18px;
  border-left: 6px solid #2563eb;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.description-box h3 {
  font-size: 20px;
  font-weight: bold;
  color: #1e40af;
  margin-bottom: 10px;
}

.description-box p {
  font-size: 18px;
  font-weight: 600;
  color: #1e293b;
  line-height: 1.6;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
.form-container {
  margin-top: 24px;
  padding: 24px;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}
.add-to-cart-btn {
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
}

.go-to-cart-btn {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-to-cart-btn:hover,
.go-to-cart-btn:hover {
  opacity: 0.9;
}